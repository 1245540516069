@use "src/assets/styles/mixins" as mixins;

.layout {
  margin: 0 auto;
}

.container {
  @include mixins.breakpoint-mobile {
    width: calc(100vw - 24px);
  }
}
