@use "src/assets/styles/typography" as typo;
@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/mixins" as mixins;

.scents {
  &__category {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 32px 0;

    @include mixins.breakpointMax(1200px) {
      flex-wrap: wrap;
      margin: 24px 0 0 0;
    }
  }

  &__single {
    font-size: 18px;
    line-height: 46.8px;
    font-weight: 500;
    font-family: typo.$josefinSans;
    color: variables.$brown;

    @include mixins.breakpointMax(1200px) {
      font-size: 16px;
      line-height: 41.6px;
      padding: 0 16px;
    }
  }
}

@include mixins.breakpointMax(1200px) {
  .scents {
    .btn-tab {
      width: 100%;
      background-color: variables.$gray;
      margin-bottom: 8px;
      position: relative;

      &:hover {
        color: variables.$light-brown;
      }

      &:after {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.8182 9.09104H7.27275V14.5456C7.27275 15.1481 7.76119 15.6365 8.36366 15.6365C8.96613 15.6365 9.45457 15.1481 9.45457 14.5456V9.09104H14.9091C15.5116 9.09104 16 8.60261 16 8.00013C16 7.39766 15.5116 6.90922 14.9091 6.90922H9.45457V1.45468C9.45457 0.852206 8.96613 0.36377 8.36366 0.36377C7.76119 0.36377 7.27275 0.852206 7.27275 1.45468V6.90922H1.8182C1.21573 6.90922 0.727295 7.39766 0.727295 8.00013C0.727295 8.60261 1.21573 9.09104 1.8182 9.09104Z' fill='%23897C74'/%3E%3C/svg%3E");
      }

      &.active {
        background-color: variables.$beige;
        color: variables.$medium-brown;

        &:after {
          background-image: url("data:image/svg+xml,%3Csvg width='16' height='4' viewBox='0 0 16 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.45456 0.90918H6V1H12V0.90918H14.5455C15.1479 0.90918 15.6364 1.39762 15.6364 2.00009C15.6364 2.60256 15.1479 3.091 14.5455 3.091H11V3H5V3.091H1.45456C0.852084 3.091 0.363647 2.60256 0.363647 2.00009C0.363647 1.39762 0.852084 0.90918 1.45456 0.90918Z' fill='%23965D30'/%3E%3C/svg%3E");
        }
      }
    }
  }
}
