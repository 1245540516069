@use "src/assets/styles/variables";
@use "src/assets/styles/typography";
@use "sass:math";

.btn {
  padding: 0 24px;
  border-radius: 72px;
  height: 53px;
  line-height: 53px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  font-family: $yesevaOne;
  display: inline-block;
  white-space: nowrap;
  border: none;

  &[disabled] {
    opacity: .7;
    cursor: default;
  }

  &-primary {
    background: $dark-beige;
    color: $brown;

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &-secondary {
    background: $brown;
    color: $white;
  }

  &-outline {
    &--white {
      color: $white;
      border: 1px solid $white;
    }

    &--brown {
      color: $brown;
      border: 1px solid $brown;
    }
  }

  &-tab {
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    color: $light-brown;
    background: none;
    display: flex;
    align-items: center;

    span {
      line-height: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;

      svg {
        height: 16px;
      }
    }

    &.active {
      background-color: $beige;
    }

    &:hover:not(.active) {
      color: $dark-beige;
    }
  }
}

.hamburger {
  margin-left: 24px;
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  z-index: 9998;

  &.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: variables.$white;
    }
  }
}

.hamburger-box {
  width: 24px;
  height: 3px * 3 + 5px * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: math.div(3px, -2);

  &,
  &::before,
  &::after {
    width: 24px;
    height: 3px;
    background-color: variables.$white;
    border-radius: 8px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: (5px + 3px) * -1;
  }

  &::after {
    bottom: (5px + 3px) * -1;
  }
}

.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease,
      opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
      transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease,
        opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
