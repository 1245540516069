@use "src/assets/styles/mixins" as mixins;
@use "src/assets/styles/variables" as variables;

.gallery {
  margin-top: 64px;

  .swiper {
    margin-top: 64px;
    padding: 0 30px;

    &:not(.swiper-lightbox) {
      .swiper-slide {
        cursor: pointer;

        figure {
        &:after {
          transition: all ease-in-out .2s;
          opacity: 0;
          width: 194px;
          height: 194px;
          background-color: rgba(61, 34, 15, 0.8);
          position: absolute;
          top: 0;
          content: '';
          background-image: url('data:image/svg+xml,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_13_1211)"><path d="M16.9217 7.32168C15.6394 6.03956 13.9348 5.33343 12.1216 5.3335C11.3182 5.3335 10.667 5.98475 10.667 6.78806C10.6671 7.59143 11.3182 8.24262 12.1216 8.24262C13.1577 8.24262 14.1318 8.646 14.8645 9.37875C15.5972 10.1114 16.0006 11.0855 16.0006 12.1216C16.0005 12.9249 16.6517 13.5762 17.4551 13.5762C17.4552 13.5762 17.4552 13.5762 17.4552 13.5762C18.2584 13.5762 18.9096 12.9251 18.9097 12.1217C18.9098 10.3086 18.2038 8.60381 16.9217 7.32168Z" fill="white"/><path d="M31.5739 29.517L21.6561 19.5991C23.2761 17.5379 24.2434 14.9406 24.2434 12.1217C24.2434 5.43781 18.8056 0 12.1216 0C12.1214 0 12.1216 0 12.1214 0C8.88375 0 5.83963 1.26094 3.55038 3.55025C1.26081 5.83981 0 8.88381 0 12.1217C0 18.8056 5.43775 24.2434 12.1216 24.2434C14.9406 24.2434 17.5379 23.2761 19.5991 21.6561L29.5169 31.574C29.8009 31.858 30.1732 32 30.5454 32C30.9177 32 31.29 31.8581 31.5739 31.5739C32.1421 31.006 32.1421 30.0849 31.5739 29.517ZM12.1216 21.3343C7.04188 21.3343 2.90913 17.2016 2.90913 12.1217C2.90913 9.66088 3.86738 7.34737 5.60738 5.60738C7.34738 3.86725 9.66081 2.90913 12.1216 2.90913C17.2016 2.90913 21.3343 7.04187 21.3343 12.1217C21.3343 17.2016 17.2016 21.3343 12.1216 21.3343Z" fill="white"/></g><defs><clipPath id="clip0_13_1211"><rect width="32" height="32" fill="white"/></clipPath></defs></svg>');
          border-radius: 8px;
          background-repeat: no-repeat;
          background-position: center;

          @include mixins.breakpoint-mobile {
            width: 140px;
            height: 140px;
          }
        }
      }

        &:hover {

          figure {
          &:after {
            opacity: 1;
          }
        }
          
        }
      }
    }

    &-slide {
     
      width: 194px;
      @include mixins.breakpoint-mobile {
        width: 140px;
      }
    }

    &-lightbox {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      margin: 0;
      padding: 0;
      left: 0;
      background: rgba(0,0,0,0.8);
      z-index: 9998;

      &.hidden {
        opacity: 0;
        pointer-events: none;;
      }
      .swiper-slide {
        width: 100%;
      }

      .swiper-button-next {
        right: 20px;
      }
    
      .swiper-button-prev {
        left: 20px;
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    color: variables.$white;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background-color: variables.$dark-beige;
    //box-shadow: 0px 76px 109px rgba(90, 84, 76, 0.12), 0px 22.9118px 32.8603px rgba(90, 84, 76, 0.0781907), 0px 9.51638px 13.6485px rgba(90, 84, 76, 0.06), 0px 3.44189px 4.93639px rgba(90, 84, 76, 0.0418093);

    &:after {
      font-size: 24px;
    }

    @include mixins.breakpoint-mobile {
      display: none;
    }

  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  &__image {
    //width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;

    img {
      width: 194px;
      height: 194px;
      border-radius: 8px;

      @include mixins.breakpoint-mobile {
        width: 140px;
        height: 140px;
      }
    }

    &--big {

      @include mixins.breakpoint-mobile {
          height: 100%;
      }
      img {
        width: auto;
        height: 100vh;
        @include mixins.breakpoint-mobile {
          width: calc(100vw - 30px);
          height: auto;
        }
      }
    }
  }

  .hamburger { // close lightbox button
    z-index: 9999;
    position: fixed;
    top: 24px;
    right: 24px;

    &.hidden {
      opacity: 0;
      pointer-events: none;;
    }
  }
}
