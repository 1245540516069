@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/typography" as typo;
@use "src/assets/styles/mixins" as mixins;
@use "sass:math";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  padding: 64px 92px 12px 92px;
  width: calc(100vw - 184px);
  z-index: 9997;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.sticky {
    @include mixins.breakpoint-desktop(true) {
      position: fixed;
      padding: 12px 92px 12px 92px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 20px 0px 20px 0px rgba(0, 0, 0, 0.5);
      animation-duration: 0.7s;
      animation-name: fadeInDown;
      animation-timing-function: ease-out;
    }
  }

  &--mobile {
    display: none;

    @include mixins.breakpoint-desktop(false) {
      display: flex;
      padding: 40px 24px 0 24px;
      width: calc(100vw - 48px);

      &.is-active.sticky { 
        background-color: transparent;
        box-shadow: none;
      }

      &.is-active {
        .logo {
          opacity: 0;
          pointer-events: none;
        }
      }

      &.sticky {
          position: fixed;
          padding: 12px 24px 12px 24px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 20px 0px 20px 0px rgba(0, 0, 0, 0.5);
          animation-duration: 0.7s;
          animation-name: fadeInDown;
          animation-timing-function: ease-out;
      }

      .logo {
        transition: all .3s ease-in-out;
        flex-grow: 1;
      }
    }
  }

  @include mixins.breakpoint-desktop(false) {
    &:not(.header--mobile) {
      width: 100vw;
      height: 100vh;
      position: fixed;
      flex-direction: column;
      padding: 0;
      justify-content: center;
      text-align: center;
      background-color: variables.$brown;
      background-blend-mode: multiply;
      opacity: 1;
      backdrop-filter: blur(1px);
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      transform: translateX(100%);
      transition: all .6s ease-in-out;

      &.is-active {
        transform: translateX(0);
      }

      .logo {
        margin-bottom: 64px;
      }
    }
  }

  &__links {

    @include mixins.breakpoint-desktop(false) {
      nav {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;
      }
    }

    a {
      color: variables.$dark-beige;
      font-family: typo.$yesevaOne;
      font-size: 20px;
      text-decoration: none;
      cursor: pointer;
      transition: all .3s ease-in-out;

      &:not(:last-child) {
        margin-right: 32px;

        @include mixins.breakpoint-desktop(false) {
          margin-right: 0;
          margin-bottom: 32px;
        }
      }

      &.active {
        color: variables.$white;
        text-decoration: underline;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__menu-toggle {
    @include mixins.breakpoint-desktop() {
      display: none;
    }

    @include mixins.breakpoint-desktop(false) {
    }
  }

  .hamburger {

    @include mixins.breakpoint-desktop() {
      display: none;
    }
  }  
}
