@use "src/assets/styles/mixins" as mixins;

.about-us {
  margin: 64px auto;

  @include mixins.breakpoint-mobile {
    margin: 0 auto;

    figure {
      height: 360px;
    }
  }
  .paragraph--medium {
    margin: 16px auto 48px auto;

    @include mixins.breakpoint-mobile {
      margin: 16px auto 24px auto;
    }
  }
}
