@use "src/assets/styles/mixins" as mixins;

.description {
  margin: 64px auto;

  @include mixins.breakpoint-mobile {
    margin: 32px auto 0 auto;
  }

  .btn {
    margin-top: 24px;
  }
}
