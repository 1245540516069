@use "src/assets/styles/variables" as bps;

@function get-bp($bp, $map: bps.$breakpoints) {
  @return map-get($map, $bp);
}

@mixin breakpointMin($value1, $value2 : false) {
  @if $value2 {
    @media screen and (min-width: $value1) and (max-width: $value2) {
      @content;
    }
  } @else {
    @media screen and (min-width: $value1) {
      @content;
    }
  }
}

@mixin breakpointMax($value1) {
  @media screen and (max-width: $value1) {
    @content;
  }
}

@mixin breakpoint-medium() {
  @include breakpointMin(get-bp(medium)) {
    @content;
  }
}

@mixin breakpoint-mobile() {
  @include breakpointMax(get-bp(medium) - 1) {
    @content;
  }
}

@mixin breakpoint-desktop($min: true) {
  @if $min {
    @include breakpointMin(get-bp(large)) {
      @content;
    }
  } @else {
    @include breakpointMax(get-bp(large) - 1) {
      @content;
    }
  }
}
