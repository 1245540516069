@use "src/assets/styles/mixins" as mixins;

.divider {
  margin: 48px auto;
  text-align: center;

  @include mixins.breakpoint-mobile {
    margin: 24px auto;
  }

  svg {
    width: 48px;
    height: 48px;

    @include mixins.breakpoint-mobile {
      width: 32px;
      height: 32px;
    }
  }
}
