@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/typography" as typo;
@use "src/assets/styles/mixins" as mixins;

.banner {
  position: relative;
  height: 642px;
  overflow: hidden;
  border-radius: 0px 0px 64px 64px;

  @include mixins.breakpoint-desktop(false) {
    height: 470px;
  }

  @include mixins.breakpoint-mobile {
    height: 360px;
  }

  &__video {
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mixins.breakpoint-mobile {
      width: auto;
      height: 100%;
    }

    &-background {
      background: #3D220F;
      mix-blend-mode: multiply;
      opacity: 0.87;
      border-radius: 0px 0px 64px 64px;
      height: 100%;
      width: 100%;
      z-index: 1;
      position: relative;
    }
  }

  &__title {
    text-align: center;
    &-wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }
  }

  &__subtitle {
    font-size: 32px;
    line-height: 39px;
    color: variables.$beige;
    margin: 0;
    font-family: typo.$waterfall;

    @include mixins.breakpoint-mobile {
      font-size: 24px;
      line-height: 29px;
    }
  }
}
