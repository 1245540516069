@use "src/assets/styles/mixins" as mixins;

.text-image {
  figure {
    @include mixins.breakpoint-mobile {
      height: 210px;
      overflow: hidden;
      position: relative;
      border-radius: 8px;
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;

      @include mixins.breakpoint-mobile {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  p {
    width: 100%;
    text-align: left;
  }
}
