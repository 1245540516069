@use "src/assets/styles/typography" as typo;
@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/mixins" as mixins;

.title {
  font-family: typo.$yesevaOne;
  font-size: 32px;
  text-align: center;
  color: variables.$brown;
  font-weight: 400;
  margin: 0;

  @include mixins.breakpoint-mobile {
    font-size: 24px;
    line-height: 27px;
  }
}
