@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/mixins" as mixins;

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yeseva+One:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Waterfall:wght@400;500&display=swap');

$lora: Lora;
$roboto: Roboto, sans-serif;
$yesevaOne: Yeseva One, sans-serif;
$josefinSans: Josefin Sans, sans-serif;
$waterfall: Waterfall, sans-serif;

.paragraph, p {
  font-size: 18px;
  line-height: 27px;
  font-family: $josefinSans;
  color: variables.$brown;
  font-weight: 300;
  margin: 0;

  @include mixins.breakpoint-mobile {
    font-size: 16px;
    line-height: 24px;
  }

  b {
    font-weight: 400;
  }

  &--medium {
    font-size: 20px;
    line-height: 30px;

    @include mixins.breakpoint-mobile {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--small {
    font-size: 12px;
    line-height: 18px;
  }

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }
}

a {
  text-decoration: none;
  color: currentColor;
}

h1 {
  font-size: 48px;
  line-height: 55px;
  font-family: $yesevaOne;
  color: variables.$white;
  margin: 0 0 24px 0;

  @include mixins.breakpoint-mobile {
    font-size: 24px;
    line-height: 27px;
    margin: 0 0 16px 0;
  }
}

.description {
  .paragraph--medium {
    margin: 16px auto 48px auto;

    @include mixins.breakpoint-mobile {
      margin: 16px auto 24px auto;
    }
  }

  &__paragraph {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h3 {
      margin-bottom: 24px;

      @include mixins.breakpoint-mobile {
        margin-left: auto;
        margin-right: auto;
      }
    }

    p:not(:last-child) {
      margin-bottom: 16px;
    }

    @include mixins.breakpoint-mobile {
      .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
