@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/typography" as typo;

.input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    input, textarea {
      border: 1px solid variables.$white;
      background: variables.$light-beige;
      resize: none;
      font-family: typo.$josefinSans;
      color: variables.$brown;
      padding: 12px 16px;
      font-size: 16px;
      line-height: 24px;

      &.invalid {
        border: 1px solid variables.$red;

        &::placeholder {
          color: variables.$red;
        }
      }
    }

    textarea {
      height: 146px;
    }
  }
}

textarea:focus, input:focus {
  outline: none;
}
