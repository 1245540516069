@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/mixins" as mixins;

.card {
  background: variables.$white;
  border-radius: 8px;
  padding: 64px 0;

  @include mixins.breakpoint-mobile {
    padding: 32px;
  }

  .paragraph--medium {
    margin: 16px auto 40px auto;
  }
}
