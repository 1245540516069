$light-beige: #F9F7F6;
$beige: #F7F2EE;
$dark-beige: #C9B9AC;
$light-brown: #897C74;
$medium-brown: #965D30;
$brown: #3D220F;
$red: #d02020;

$white: #FFFFFF;
$gray: #FBFBFB;
$black: #555555;

$breakpoints: (
        'xsmall': 399px,
        'small': 499px,
        'medium': 767px,
        'large': 1100px,
        'xlarge': 1300px,
) !default;
