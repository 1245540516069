@use "src/assets/styles/mixins" as mixins;

.rules {

  &__button {
    text-align: center;
    margin-top: 40px;

    @include mixins.breakpoint-mobile {
      margin-top: 24px;
    }
  }

  &__single {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    &-icon {
      width: 48px;
      height: 48px;
      min-width: 48px;
      background: rgba(201, 185, 172, 0.16);
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
    }
  }
}
