@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import "assets/styles/variables";
@import "assets/styles/typography";
@import "assets/styles/buttons";
@import "assets/styles/card";
@import "assets/styles/inputs";

body {
  background-color: $beige;
}
