@use "src/assets/styles/mixins" as mixins;

.contact-form {

  .title {
    margin-bottom: 16px;
    margin-top: 64px;
    text-align: left;

    @include mixins.breakpoint-mobile {
      margin-top: 0;
      text-align: center;
    }
  }

  .paragraph--small {
    margin-bottom: 24px;
  }

  p ~ .divider {
    margin: 32px 0;
    text-align: left;

    @include mixins.breakpoint-mobile {
      text-align: center;
    }
  }

  &__card {
    padding: 64px;

    @include mixins.breakpoint-mobile {
      padding: 32px;
      margin-bottom: 24px;
    }

    .title {
      margin-top: 0;
      text-align: center;
    }
  }

  &__scents {
    font-size: 14px;
    line-height: 36px;
    margin-bottom: 24px;
    text-align: center;

    a {
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .btn {
    margin-top: 20px;
    width: 100%;
  }
}
